
export const isValid = (password) => {
  const lengthCheck = /.{8,}/;
  const lowercaseCheck = /[a-z]/;
  const uppercaseCheck = /[A-Z]/;
  const digitCheck = /\d/;
  const symbolCheck = /[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E]/;
  const halfWidthCheck = /^[\x21-\x7E]*$/;

  return lengthCheck.test(password) &&
      lowercaseCheck.test(password) &&
      uppercaseCheck.test(password) &&
      digitCheck.test(password) &&
      symbolCheck.test(password) &&
      halfWidthCheck.test(password)
}
