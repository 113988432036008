<template>
  <v-overlay v-if="isLoading" opacity="0.4">
    <div class="loading" v-if="isLoading">
      <div class="loading__container">
        <vue-loading
          type="spin"
          :size="{ width: '100px', height: '100px' }"
          color="#009CE6"
        ></vue-loading>
      </div>
    </div>
  </v-overlay>
</template>

<script>
import { VueLoading } from 'vue-loading-template'
export default {
  components: {
    VueLoading,
  },
  computed: {
    isLoading() {
      return this.$store.getters['isLoading']
    },
  },
}
</script>
