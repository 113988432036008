<template>
    <ModalDisplay>
      <v-row class="d-flex justify-center button-wrapper">
        <v-col class="d-flex ma-0" light>
          <v-col class="pa-0 ma-0 d-flex justify-center">
            <v-btn class="mr-12 gradient" @click="goToPrevYear">
              <v-icon>mdi-chevron-left</v-icon>前年
            </v-btn>
            <v-btn class="gradient" @click="goToNextYear">翌年<v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <div :class="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl? 'mobile-area': ''">
        <v-row class="d-flex" v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl">
          <v-col class="d-flex justify-center py-0" cols="12">
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-1'" ref="datepicker" no-title
              :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-2'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-3'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-4'" no-title :day-format="(date) => new Date(date).getDate()" />
          </v-col>
          <v-col class="d-flex justify-center py-0" cols="12">
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-5'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-6'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-7'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-8'" no-title :day-format="(date) => new Date(date).getDate()" />
          </v-col>
          <v-col class="d-flex justify-center py-0" cols="12">
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-9'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-10'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-11'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header-mobile" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-12'" no-title :day-format="(date) => new Date(date).getDate()" />
          </v-col>
        </v-row>
        <v-row class="d-flex" v-else>
          <v-col class="d-flex justify-center py-0" cols="12">
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-1'" ref="datepicker" no-title
              :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-2'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-3'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-4'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-5'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-6'" no-title :day-format="(date) => new Date(date).getDate()" />
          </v-col>
          <v-col class="d-flex justify-center py-0" cols="12">
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-7'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-8'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-9'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-10'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-11'" no-title :day-format="(date) => new Date(date).getDate()" />
            <v-date-picker class="ma-1 picker-header" width="180px" light multiple v-model="selectedHoliday" :disabled="showConfirmSave || !authority"
              locale="jp-ja" :picker-date="year + '-12'" no-title :day-format="(date) => new Date(date).getDate()" />
          </v-col>
        </v-row>
      </div>
      <v-row class="d-flex justify-center button-wrapper">
        <v-col class="d-flex mt-4" light>
          <v-col class="pa-0 d-flex justify-center">
            <div v-if="showConfirmCancel">
              <div class="d-flex">
                <span class="red--text text-caption font-weight-bold">変更を破棄して問題なければ「OK」を押してください。</span>
              </div>
              <div class="mt-2 d-flex justify-center">
                <v-btn
                  class="mr-6"
                  color="#8AABD0"
                  @click="() => showConfirmCancel = !showConfirmCancel"
                  >キャンセル</v-btn>
                  <v-btn
                  class="gradient"
                  dark
                  @click="onClickConfimCancel"
                  >OK</v-btn>
              </div>
            </div>
            <div v-else-if="showConfirmSave">
              <div class="d-flex">
                <span class="red--text text-caption font-weight-bold">保存して問題なければ「OK」を押してください。</span>
              </div>
              <div class="mt-2 d-flex justify-center">
                <v-btn
                  class="mr-6"
                  color="#8AABD0"
                  @click="() => showConfirmSave = !showConfirmSave"
                  >キャンセル</v-btn>
                  <v-btn
                  class="gradient"
                  dark
                  @click="registerHolidays"
                  >OK</v-btn>
              </div>
            </div>
            <div v-else>
              <v-btn class="mr-12" color="#8AABD0" @click="onClickClose">閉じる</v-btn>
              <v-btn
                v-if="authority"
                :class="['gradient', 'white--text']"
                @click="() => showConfirmSave = !showConfirmSave">保存</v-btn>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </ModalDisplay>
</template>

<script>
import dayjs from 'dayjs'
import ModalDisplay from '@/components/ModalDisplay.vue'
import $http from '@/services/httpService'
import loading from '@/services/loadingService'
import { getLocalStorage } from '@/store/localstorage'

export default {
  components: {
    ModalDisplay
  },
  data() {
    return {
      selectedHoliday: [],
      year: dayjs().format('YYYY'),
      showConfirmCancel: false,
      showConfirmSave: false,
    }
  },

  created() {
    this.fetchThisYearNoWorkingDay()
  },
  computed: {
    authority() {
      const user = getLocalStorage('loginUser')
      return user.authority
    },
  },
  methods: {
    closeModal() {
      this.$emit('modal', false)
    },
    onClickClose() {
      if (this.authority) {
        this.showConfirmCancel = !this.showConfirmCancel
      } else {
        this.closeModal()
      }
    },
    onClickConfimCancel(){
      this.showConfirmCancel = false
      this.closeModal()
    },
    goToPrevYear() {
      this.year = Number(this.year) - 1
      this.fetchThisYearNoWorkingDay()
    },
    goToNextYear() {
      this.year = Number(this.year) + 1
      this.fetchThisYearNoWorkingDay()
    },
    async fetchThisYearNoWorkingDay() {
      loading.show()
      const local = getLocalStorage('loginUser')
      const contractId = local.contract_id
      const params = {
        year: this.year,
      }
      try {
        const response = await $http.get('/iot-sensor-cloud/1.0/holidays/' + contractId, params)
        this.selectedHoliday = response.data.holidays
      } finally {
        loading.close()
      }
    },
    async registerHolidays() {
      try {
        loading.show()
        const local = getLocalStorage('loginUser')
        const contractId = local.contract_id
        const params = {
          year: this.year,
          holidays: this.selectedHoliday,
        }
        await $http.post('/iot-sensor-cloud/1.0/holidays/' + contractId, params)
        this.closeModal()
        this.showConfirmSave = false
        loading.close()
      } catch {
        loading.close()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, #009ce6, #006ae7);
}


.picker-header ::v-deep .mdi {
  display: none;
}

.picker-header ::v-deep .v-date-picker-header {
  pointer-events: none;
  & .v-btn {
    display: none;
  }
}

.picker-header-mobile ::v-deep {
  & .v-date-picker-header {
    pointer-events: none;
    padding: 4px;
    & .v-btn {
      display: none;
    }

    & button {
      font-size: 0.75rem;
      padding: 0px;
    }
  }
  & .v-date-picker-table {
    height: unset;

    & th {
      font-size: 10px;
    }
    & .v-btn__content {
      font-size: 10px;
    }
  }
  & .v-date-picker-table--date {
    & .v-btn {
      height: unset;
      width : unset;
    }
  }
}

.mobile-area {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.button-wrapper {
  max-width: 1160px;
  margin: 0 auto;
}
</style>
