<template>
  <ModalDisplay>
    <v-card light class="pa-8">
      <div v-if="disabledMfa" class="text-caption red--text my-2"> 管理者により多要素認証が無効化されています。</div>
      <v-checkbox
        v-model="mfaEnabled"
        class="ma-0"
        color="indigo"
        hide-details
        @change="onChangeMfaEnabled"
        :disabled="disabledMfa">
        <template v-slot:label>
          <div> 多要素認証（メール）を有効化する</div>
        </template>
      </v-checkbox>
      <v-col class="pa-0 mt-8 d-flex justify-end">
        <v-btn class="gradient white--text" :loading="loading" @click="close">閉じる</v-btn>
      </v-col>
    </v-card>
  </ModalDisplay>
</template>

<script>
import { fetchLoginUser } from '@/api/user'
import ModalDisplay from '@/components/ModalDisplay.vue'
import { notifyError } from '@/lib/util/toast'
import $http from '@/services/httpService'
import loading from '@/services/loadingService'
import { getLocalStorage } from '@/store/localstorage'

export default {
  components: {
    ModalDisplay
  },
  created() {
    this.fetch()
  },
  data() {
    const loginUser = getLocalStorage('loginUser')
    return {
      loading: false,
      loginUser: loginUser,
      mfaEnabled: loginUser.mfa_enabled === 1,
      mfaFlag: false,
    }
  },
  computed: {
    disabledMfa() {
      return !this.mfaFlag
    },
  },
  methods: {

    async fetch() {
      try {
        loading.show()
        const loginUser = getLocalStorage('loginUser')
        const params = {
          contract_id: loginUser.contract_id,
        }
        const res = await $http.get(`/iot-sensor-cloud/1.0/security-policy`, params)
        console.log('res =>', res)
        this.mfaFlag = res.data.mfa_flag === 1
      } catch(e) {
        notifyError('同期に失敗しました。', e)
      } finally {
        loading.close()
      }
    },

    close() {
      this.$emit('switchChangeMfa', false)
    },

    onChangeMfaEnabled() {
      this.loading = true
      $http.put('/auth/1.0/user/' + this.loginUser.app_user_id, {
        contractId: this.loginUser.contract_id,
        enable_mfa: this.mfaEnabled? 1: 0
      })
      .then( () => {
        return fetchLoginUser(this.$store)
      })
      .finally(()=> {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, #009ce6, #006ae7);
}
</style>
