<template>
  <v-app>
    <div v-if="isMaintenance">
      <v-app-bar flat app>
        <img src="/SIRC_LOGO.png" height="28px" width="28px" class="mr-1" />
        <h3 class="text-h5 font-weight-bold black--text">SIRC クラウド</h3>
      </v-app-bar>
      <div class="mt-16 text-center">
        <h2 class="my-2">メンテナンス中のためSIRCクラウドはご利用いただけません。</h2>
        <h3 v-if="maintenanceTime" class="red--text">{{maintenanceTime}}</h3>
        <h3 v-else class="red--text">少し時間をおいてから再度ログインください。</h3>
        <h5 v-if="isHrefMaintenance" class="my-2">メンテナンス終了後は <a :href="redirectURL">こちら</a>からログインできます。</h5>
      </div>
    </div>
    <div v-else>
      <HeaderMenu
        v-if="isShow"
        @switchUserInfo="switchUserInfo"
        @switchSendMail="switchSendMail"
        @switchChangePassword="switchChangePassword"
        @switchChangeMfa="switchChangeMfa"
        @switchSensorGroupSetting="switchSensorGroupSetting"
        @switchHolidaySetting="switchHolidaySetting"
        :drawer="drawer"
        @switchDrawer="switchDrawer"/>
      <SidebarMenu v-if="isShow" :pDrawer="drawer" @switchDrawer="switchDrawer" />
      <v-main>
        <router-view />
      </v-main>
      <div v-if="showContractInfo" class="confirm">
        <v-overlay>
          <v-card light class="pa-8">
            <table>
              <tr>
                <th class="py-4 px-8">契約ID</th>
                <td>{{ userData.contract_id }}</td>
              </tr>
              <tr>
                <th class="py-4 px-8">ユーザ名</th>
                <td>{{ userData.user_name }}</td>
              </tr>
              <tr>
                <th class="py-4 px-8">メールアドレス</th>
                <td>{{ userData.mail_address }}</td>
              </tr>
              <tr>
                <th class="py-4 px-8">権限</th>
                <td v-if="userData.authority">管理者</td>
                <td v-else>一般</td>
              </tr>
            </table>
            <v-col class="pa-0 mt-8 d-flex justify-end">
              <v-btn class="gradient white--text" @click="() => showContractInfo=false">閉じる</v-btn>
            </v-col>
          </v-card>
        </v-overlay>
      </div>
      <MailSendModal v-if="showSendMail" @switchSendMail="switchSendMail"/>
      <ConductChangePassword v-if="showChangePassword" @switchChangePassword="switchChangePassword"/>
      <MfaSettingModal v-if="showChangeMfa" @switchChangeMfa="switchChangeMfa"/>
      <ModalDisplay v-if="showFirstSensorGroupSetting">
        <div>
          <v-card light width="400px">
            <v-card-title>センサグループ設定</v-card-title>
            <v-btn
              v-if="authority"
              class="ml-4 gradient white--text"
              @click="onClickCreateSensorGroup"
              >センサグループ作成
            </v-btn>
            <v-col>
              <span class="text-subtitle-2">設定するセンサグループを選択</span>
              <v-select
                class="mt-2"
                v-model="sensorGroupId"
                flat
                outlined
                dense
                solo
                item-value="id"
                item-text="name"
                :items="sensorGroups"
                :label="'センサグループ:'"
              ></v-select>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn class="mr-6" color="#8AABD0" @click="closeSensorGroupModal">キャンセル</v-btn>
              <v-btn
                :disabled="!sensorGroupId"
                :class="sensorGroupId ? ['gradient', 'white--text'] : 'v-btn-disabled'"
                @click="onClickEditSensorGroup"
                >編集</v-btn>
            </v-col>
          </v-card>
        </div>
      </ModalDisplay>
      <SensorGroupSettingModal v-if="showSecondSensorGroupSetting" :ttl="sensorGroupTtl" :selectedSensorGroup="[sensorGroupId]" @modal="setSensorGroupSettingModal"/>
      <HolidaySettingModal v-if="showHolidaySetting" @modal="setHolidaySettingModal"/>
      <LoadingItem />
    </div>
  </v-app>
</template>

<script>
import { fetchAndNotifyAllSensorGroups } from '@/api/sensor'
import SidebarMenu from '@/components/SidebarMenu.vue'
import LoadingItem from '@/components/LoadingItem.vue'
import HeaderMenu from '@/components/HeaderMenu.vue'
import ConductChangePassword from '@/components/ConductChangePassword.vue'
import MailSendModal from '@/components/MailSendModal.vue'
import MfaSettingModal from '@/components/MfaSettingModal.vue'
import HolidaySettingModal from '@/components/HolidaySettingModal.vue'
import ModalDisplay from '@/components/ModalDisplay.vue'
import SensorGroupSettingModal from '@/components/SensorGroupSettingModal.vue'
import { sensorGroupEditMode } from '@/const'
import loading from '@/services/loadingService'
import { getLocalStorage } from '@/store/localstorage'

export default {
  components: {
    SidebarMenu,
    LoadingItem,
    HeaderMenu,
    ConductChangePassword,
    MailSendModal,
    MfaSettingModal,
    HolidaySettingModal,
    SensorGroupSettingModal,
    ModalDisplay
  },

  data() {
    return {
      drawer: true,
      showContractInfo: false,
      showSendMail: false,
      showChangePassword: false,
      showChangeMfa: false,
      showFirstSensorGroupSetting: false,
      showSecondSensorGroupSetting: false,
      showHolidaySetting: false,
      sensorGroupTtl: '',
      sensorGroupId: null,
      sensorGroups: [],
    }
  },

  computed: {
    isShow() {
      return this.$route.name !== 'login'
    },
    isMaintenance() {
      const value = process.env.VUE_APP_IS_MAINTENANCE
      return value && value === "true" || this.isHrefMaintenance;
    },
    isHrefMaintenance(){
      return location.href.includes('maintenance')
    },
    maintenanceTime() {
      if (this.isMaintenance) {
        const time = process.env.VUE_APP_MAINTENANCE_TIME
        if (time) {
          return `予定時間：${time}`
        }
      }

      return undefined
    },
    redirectURL(){
      return location.href.replace('maintenance', '')
    },
    userData() {
      return getLocalStorage('loginUser')
    },
    authority() {
      const user = getLocalStorage('loginUser')
      return user.authority
    },
  },
  methods: {
    switchUserInfo(value) {
      this.showContractInfo = value
    },
    switchSendMail(value) {
      this.showSendMail = value
    },
    switchChangePassword(value) {
      this.showChangePassword = value
    },
    switchChangeMfa(value) {
      this.showChangeMfa = value
    },
    switchSensorGroupSetting(value) {
      this.showFirstSensorGroupSetting = value
      if (this.showFirstSensorGroupSetting) {
        loading.show()
        fetchAndNotifyAllSensorGroups(this.$store)
          .then((res) => {
            this.sensorGroups = res.sensorGroup
          })
          .finally(() => loading.close())
      }
    },
    switchHolidaySetting(value) {
      this.showHolidaySetting = value
    },
    switchDrawer(value) {
      this.drawer = value
    },
    onClickCreateSensorGroup() {
      this.sensorGroupTtl = sensorGroupEditMode.CREATE
      this.showFirstSensorGroupSetting = false
      this.showSecondSensorGroupSetting = true
    },
    onClickEditSensorGroup() {
      this.sensorGroupTtl = sensorGroupEditMode.EDIT
      this.showFirstSensorGroupSetting = false
      this.showSecondSensorGroupSetting = true
    },
    setSensorGroupSettingModal(bool) {
      this.showSecondSensorGroupSetting = bool
    },
    closeSensorGroupModal() {
      this.showFirstSensorGroupSetting = false
      this.sensorGroupId = null
      this.sensorGroups = []
    },
    setHolidaySettingModal(bool) {
      this.showHolidaySetting = bool
    },
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, #009ce6, #006ae7);
}

</style>
