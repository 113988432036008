<template>
  <div class="header">
    <v-app-bar flat app>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0 d-flex justify-space-between">
          <v-col class="pa-0 ma-0 d-flex" cols="4" xl="2">
            <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.xl" @click.stop="onClickAppBarIcon"></v-app-bar-nav-icon>
            <router-link to="/" class="d-flex align-center">
              <img src="/SIRC_LOGO.png" height="28px" width="28px" class="mr-1" />
              <h3 class="text-h5 font-weight-bold black--text">SIRC クラウド</h3>
            </router-link>
          </v-col>
          <v-col class="pa-0 ma-0 d-flex justify-end align-center" cols="6">
            <p class="mb-0 mr-4" v-if="userName">{{ userName }} 様</p>
            <div class="d-flex justify-end">
              <v-btn icon large
                class="mx-3"
                v-bind:href=manualURL
                @click="accessLog"
                target="_blank"
              >
                <div class="text-center">
                  <v-icon color="#0066CC">mdi-help-circle-outline</v-icon>
                  <div class="text-caption font-weight-bold"> 取扱説明書 </div>
                </div>
              </v-btn>
              <div class="mx-2">
                <v-btn icon large @click="onClickUserInfomation">
                  <div class="text-center">
                    <v-icon color="#0066CC">mdi-account</v-icon>
                    <div class="text-caption font-weight-bold"> ユーザ </div>
                  </div>
                </v-btn>
              </div>
              <div class="mx-2">
                <v-btn icon large @click="onClickSettings">
                  <div class="text-center">
                    <v-icon color="#0066CC">mdi-cog</v-icon>
                    <div class="text-caption font-weight-bold"> 総合設定 </div>
                  </div>
                </v-btn>
              </div>
              <div class="ml-3 mr-4">
                <v-btn icon large @click="logout">
                  <div class="text-center">
                    <v-icon color="#0066CC">mdi-logout</v-icon>
                    <div class="text-caption font-weight-bold"> ログアウト </div>
                  </div>
                </v-btn>
              </div>
            </div>
            <div v-if="showUserArea" flat class="header__nav_user">
              <div class="d-flex flex-column">
                <v-btn block tile @click="openUserInfo">ユーザ情報</v-btn>
                <v-btn block tile @click="openChangePassword">パスワード変更</v-btn>
                <v-btn block tile @click="openChangeMfa">多要素認証設定</v-btn>
                <v-btn block tile @click="openSendMail">メール配信</v-btn>
              </div>
            </div>
            <div v-if="showSettingArea" flat class="header__nav_setting">
              <div class="d-flex flex-column">
                <v-btn block tile @click="goToContractInformation" v-if="authority">契約確認</v-btn>
                <v-btn block tile @click="goToSecurityPolicy" v-if="authority">セキュリティポリシー</v-btn>
                <v-btn block tile @click="goToUserList" v-if="authority">ユーザ設定</v-btn>
                <v-btn block tile @click="goToAlarm">アラーム・通知設定</v-btn>
                <v-btn block tile @click="openSensorGroupSetting">センサグループ設定</v-btn>
                <v-btn block tile @click="openHolidaySetting">休日登録設定</v-btn>
              </div>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import $http from '@/services/httpService'
import { getLocalStorage } from '@/store/localstorage'
export default {
  props: {
    drawer: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      showUserArea: false,
      showSettingArea: false,
    }
  },
  computed: {
    userName() {
      const user = getLocalStorage('loginUser')
      if (user === null) {
        return ''
      }
      return user.user_name
    },
    authority() {
      const user = getLocalStorage('loginUser')
      return user.authority
    },
    manualURL() {
      return process.env.VUE_APP_MANUAL_URL;
    }
  },
  methods: {
    onClickAppBarIcon() {
      this.$emit('switchDrawer', !this.drawer)
    },
    onClickUserInfomation() {
      this.showUserArea = !this.showUserArea
      if (this.showUserArea) {
        this.showSettingArea = false
      }
    },

    onClickSettings() {
      this.showSettingArea = !this.showSettingArea
      if (this.showSettingArea) {
        this.showUserArea = false
      }
    },

    openUserInfo() {
      this.$emit('switchUserInfo', true)
      this.showUserArea = false
    },
    openChangePassword() {
      this.$emit('switchChangePassword', true)
      this.showUserArea = false
    },
    openChangeMfa() {
      this.$emit('switchChangeMfa', true)
      this.showUserArea = false
    },
    openSendMail() {
      this.$emit('switchSendMail', true)
      this.showUserArea = false
    },
    async logout() {
      this.$router.push({ name: 'login' })
      this.showUserArea = false
    },


    goToContractInformation() {
      if (this.$route.path !== '/contract-info') {
        this.$router.push({ name: 'contractInfo' })
      }
      this.showSettingArea = false
    },
    goToSecurityPolicy() {
      if (this.$route.path !== '/security-policy') {
        this.$router.push({ name: 'securityPolicy' })
      }
      this.showSettingArea = false
    },
    goToUserList() {
      if (this.$route.path !== '/user-list') {
        this.$router.push({ name: 'userList' })
      }
      this.showSettingArea = false
    },
    goToAlarm() {
      if (this.$route.path !== '/alarm') {
        this.$router.push({ name: 'alarm' })
      }
      this.showSettingArea = false
    },
    openSensorGroupSetting() {
      this.$emit('switchSensorGroupSetting', true)
      this.showSettingArea = false
    },
    openHolidaySetting() {
      this.$emit('switchHolidaySetting', true)
      this.showSettingArea = false
    },

    accessLog() {
      $http.accesslog('manual')
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  &__nav_user {
    position: absolute;
    top: 65px;
    right: 105px;
  }
  &__nav_setting {
    position: absolute;
    top: 65px;
    right: 10px;
  }
}

.router-link-active {
  text-decoration: none;
}
</style>
