import { setLocalStorage } from '@/store/localstorage'

const initialState = () => {
  return {
    authenticatedUserName: {},
    loginUser: {},
    allUsers: {}
  }
}
const userStore = {
  namespaced: true,
  state: initialState,
  getters: {
    authenticatedUserName: (state) => state.authenticatedUserName,
    loginUser: (state) => state.loginUser,
    allUsers: (state) => state.allUsers,
  },
  mutations: {
    setAuthenticatedUserName(state, value) {
      // FIXME: want to use vuex-persistedstate
      setLocalStorage('authenticatedUserName', value)
      state.authenticatedUserName = value
    },
    setLoginUser(state, user) {
      // FIXME: want to use vuex-persistedstate
      setLocalStorage('loginUser', user)
      state.loginUser = user
    },
    setAllUsers(state, users) {
      state.allUsers = users
    },
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    saveAuthenticatedUserName({ commit }, value) {
      commit('setAuthenticatedUserName', value)
    },
    saveLoginUser({ commit }, user) {
      commit('setLoginUser', user)
    },
    saveAllUsers({ commit }, users) {
      commit('setAllUsers', users)
    },
  }
}

export default userStore
