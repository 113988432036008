import $http from '@/services/httpService'
import { getLocalStorage } from '@/store/localstorage'

export const getAllValidGateways = async () => {
  const loginUser = getLocalStorage('loginUser')
  const params = {
    contractId: loginUser.contract_id,
  }
  return $http.get('/iot-sensor-cloud/1.0/gateways/valid', params)
}

export const fetchAllValidGateways = async (store) => {
  return getAllValidGateways().then((res) => {
    store.dispatch('data/saveGateways', res.data.gateway)
    return res.data
  })
}
