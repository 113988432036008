<template>
  <ModalDisplay>
    <v-card light class="pa-4">
      <form @submit.prevent="">
        <v-row>
          <v-col>
            <v-sheet class="ma-auto" width="400px">
              <h2 class="text-display-1 text-center my-4">パスワード変更</h2>
              <v-col class="py-1">
                <p class="mb-1">現在のパスワード</p>
                <v-text-field hide-details dense solo flat outlined v-model="form.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword" />
              </v-col>
              <v-col class="py-1">
                <p class="mb-1">新しいパスワード</p>
                <v-text-field hide-details dense solo flat outlined v-model="newPassword"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showNewPassword ? 'text' : 'password'"
                  @keyup="checkPassword"
                  @click:append="showNewPassword = !showNewPassword" />
                <span v-if="limitPassword" class="red--text text-caption">{{ limitPassword }}</span>
              </v-col>
              <v-col class="py-1">
                <p class="mb-1">新しいパスワードの確認</p>
                <v-text-field hide-details dense solo flat outlined v-model="confirmNewPassword"
                  :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmNewPassword ? 'text' : 'password'" @keyup="checkPassword"
                  @click:append="showConfirmNewPassword = !showConfirmNewPassword" />
                <span v-if="errorPasswordConfirm" class="red--text text-caption">{{ errorPasswordConfirm }}</span>
              </v-col>
              <p v-if="errorMessage" class="my-2 pr-4 text-right text-caption red--text font-weight-bold">
                {{ errorMessage }}
              </p>
              <v-col class="d-flex justify-end mt-4">
                <v-btn color="#8AABD0" class="mr-6" @click="closeChangePassword">キャンセル</v-btn>
                <v-btn :class="isFormData ? ['disabled'] : ['gradient', 'white--text']" @click="conductChangePassword"
                  :loading="btnLoading" :disabled="isFormData">保存</v-btn>
              </v-col>
            </v-sheet>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </ModalDisplay>
</template>

<script>
import ModalDisplay from '@/components/ModalDisplay.vue'
import { notifySuccess } from '@/lib/util/toast'
import { isValid } from '@/lib/util/password'
import auth from '@/services/authService'
import $http from '@/services/httpService'
import { getLocalStorage } from '@/store/localstorage'

export default {
  components: {
    ModalDisplay
  },
  data() {
    return {
      form: {
        contractId: getLocalStorage('loginUser').contract_id,
        userName: this.$store.state.user.authenticatedUserName,
        password: '',
      },
      newPassword: '',
      confirmNewPassword: '',
      showPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      changePasswordCompleted: false,
      limitPassword: '',
      errorPasswordConfirm: '',
      btnLoading: false,
      errorMessage: ''
    }
  },

  computed: {
    isFormData() {
      return (
        this.form.password === '' ||
        this.newPassword === '' ||
        this.confirmNewPassword === '' ||
        this.limitPassword !== '' ||
        this.errorPasswordConfirm !== ''
      )
    }
  },

  methods: {

    closeChangePassword() {
      this.form.password = ''
      this.newPassword = ''
      this.confirmNewPassword = ''
      this.limitPassword = ''
      this.errorPasswordConfirm = ''
      this.showPassword = false
      this.showNewPassword = false
      this.showConfirmNewPassword = false
      this.$emit('switchChangePassword', false)
    },

    async conductChangePassword() {
      try {
        this.btnLoading = true
        await auth.conductChangePassword(this.form)
          .then(() => {
            return this.sendNewPassword().catch((e) => {
              return Promise.reject(e)
            })
          }).catch((e)=> {
            if (e.response.data.message === 'Incorrect Username or password') {
              this.errorMessage = '現在のパスワードが正しくありません。'
            } else {
              this.errorMessage = '入力内容に問題があり、パスワードを変更できませんでした。'
            }
            throw e
          })
      } catch {
        this.btnLoading = false
      }
    },

    async sendNewPassword() {
      try {
        const appUserId = getLocalStorage('loginUser').app_user_id
        const params = {
          contractId: this.form.contractId,
          password: this.newPassword,
          authority: getLocalStorage('loginUser').authority
        }
        await $http.put('/auth/1.0/user/' + appUserId, params)
        this.btnLoading = false
        this.closeChangePassword()
        notifySuccess('パスワードを変更しました')
      } catch (e) {
        this.btnLoading = false
        throw e
      }
    },

    checkPassword() {
      if (!isValid(this.newPassword)) {
        this.limitPassword = "パスワードは半角英字（大文字・小文字両方）・半角数字・半角記号を含む8文字以上で設定して下さい。"
      } else {
        this.limitPassword = ""
      }
      // パスワードとパスワード(確認)が一致しているか確認
      if (this.newPassword !== this.confirmNewPassword && this.confirmNewPassword !== "") {
        this.errorPasswordConfirm = "パスワードが一致していません"
      } else {
        this.errorPasswordConfirm = ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, #009ce6, #006ae7);
}
</style>
