<template>
  <div class="sidebar">
    <v-navigation-drawer
      app
      mobile-breakpoint="1904"
      class="sidebar__nav"
      v-model="drawer"
      :permanent="$vuetify.breakpoint.xl"
    >
      <v-list class="mb-4">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-center white--text">
              メニュー
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       </v-list>
      <v-list-item-group active-class="bg-active">
        <v-list v-for="item in items" :key="item.title" class="pa-0">
          <v-list-group
            v-if="item.childs"
            :value="true"
            :disabled="unableTransitionFromSideBarMenu"
            no-action
          >
            <template v-slot:activator>
              <v-icon small dark class="mr-4">{{ item.icon }}</v-icon>
              <v-list-item-title class="text-caption white--text"> {{ item.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="child in item.childs"
              class="sidebar__list pl-0 white--text"
              :key="child.title"
              :title="child.title"
              :to="child.link"
              :disabled="unableTransitionFromSideBarMenu"
            >
              <v-list-item-content class="ml-16">
                <v-list-item-title class="text-caption"> {{ child.title }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            class="sidebar__list white--text"
            :key="item.title"
            :title="item.title"
            :to="item.link"
            :disabled="unableTransitionFromSideBarMenu"
          >
            <v-icon small dark class="mr-4">{{ item.icon }}</v-icon>
            <v-list-item-title class="text-caption">
              <div>{{ item.title }}</div>
              <div v-if="item.oldTitle"> {{ item.oldTitle }}</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    pDrawer: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      items: [
        {
          title: 'ダッシュボード',
          link: '/',
          icon: 'mdi-monitor-dashboard',
        },
        {
          title: 'トレンドグラフ',
          // oldTitle: '（旧：トレンドグラフ）',
          icon: 'mdi-update',
          link: '/realtime-monitor'
        },
        {
          title: '分析',
          // oldTitle: '（旧：データ詳細）',
          link: '/analysis',
          icon: 'mdi-chart-line',
        },
        {
          title: 'アラーム・通知設定',
          // oldTitle: '（旧：アラーム設定）',
          link: '/alarm',
          icon: 'mdi-bell',
        },
        {
          title: 'データダウンロード',
          link: '/data-download',
          icon: 'mdi-download'
        },
        {
          title: 'レポート',
          // oldTitle: '（旧：環境負荷算出）',
          link: '/report',
          icon: 'mdi-message-text',
        },
        // {
        //   title: 'ライブラリ',
        //   link: '/library',
        //   icon: 'mdi-library'
        // },
      ]
    }
  },
  computed: {
    drawer: {
      get: function () {
        return this.pDrawer
      },
      set: function(v) {
        this.$emit('switchDrawer', v)
      }
    },
    unableTransitionFromSideBarMenu() {
      return this.$store.state.unableTransitionFromSideBarMenu
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__nav {
    background: linear-gradient(to bottom, #009ce6, #006ae7);
  }
}

::v-deep .v-list-item__icon {
  margin-left: 0px !important;
}
.bg-active {
  background-color: #fff;
  color: #006ae7 !important;
}
</style>
