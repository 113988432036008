

class Logger {

  debug(...args) {
    if (process.env.VUE_APP_USE_LOGGER) {
      console.debug(...args)
    }
  }

  info(...args) {
    if (process.env.VUE_APP_USE_LOGGER) {
      console.info(...args)
    }
  }

  warn(...args) {
    if (process.env.VUE_APP_USE_LOGGER) {
      console.warn(...args)
    }
  }

  error(...args) {
    if (process.env.VUE_APP_USE_LOGGER) {
      console.error(...args)
    }
  }

}
const logger = new Logger()
export default logger
