import $http from '@/services/httpService'
import { getLocalStorage } from '@/store/localstorage'

export const getLoginUser = async () => {
  return $http.get(`auth/1.0/login-user`)
}

export const getAllUsers = async () => {
  const loginUser = getLocalStorage('loginUser')
  const params = {
    contractId: loginUser.contract_id,
  }
  return $http.get('/iot-sensor-cloud/1.0/users-info', params)
}

export const createUser = async (params) => {
  return $http.post('/auth/1.0/user', params)
}

export const putUser = async (params) => {
  return $http.put('/iot-sensor-cloud/1.0/user', params)
}

export const fetchLoginUser = async (store) => {
  return getLoginUser().then((res) => {
    store.dispatch('user/saveLoginUser', res.data)
    return res.data
  })
}

export const fetchAllUsers = async (store) => {
  return getAllUsers().then((res) => {
    store.dispatch('user/saveAllUsers', res.data)
    return res.data
  })
}
