import { notifyError } from '@/lib/util/toast'
import $http from '@/services/httpService'
import { getLocalStorage } from '@/store/localstorage'

export const getSensorTypes = async () => {
  return $http.get('/iot-sensor-cloud/1.0/sensor-type')
}

export const getSensorDataAggregationTypes = async () => {
  return $http.get('/iot-sensor-cloud/1.0/data-aggregation-method')
}

export const getAllSensors = async () => {
  const local = getLocalStorage('loginUser')
  return $http.get(
    '/iot-sensor-cloud/1.0/registered-sensors/' + local.contract_id
  )
}

export const getSensorGroupDetail = async (sensorGroupId) => {
  return $http.get(
    '/iot-sensor-cloud/1.0/sensors/sensor-groups/' + sensorGroupId
  )
}

export const getAllSensorGroups = async () => {
  const loginUser = getLocalStorage('loginUser')
  const params = {
    id: loginUser.contract_id,
  }
  return $http.get('/iot-sensor-cloud/1.0/sensor-groups', params)
}

export const getSensorCoefficientHistory = async (sensorId, sensorGroupId) => {
  const params = {
    sensor_id: sensorId,
    sensor_group_id: sensorGroupId
  }
  return $http.get('/iot-sensor-cloud/1.0/sensors/coefficient-history', params)
}

export const updateSensorCoefficientHistory = async (params) => {
  return $http.post('/iot-sensor-cloud/1.0/sensors/coefficient-history', params)
}

export const createSensorGroup = async (params) => {
  return $http.post('/iot-sensor-cloud/1.0/sensor-group', params)
}

export const putSensor = async (id, params) => {
  return $http.put('/iot-sensor-cloud/1.0/sensors/' + id, params)
}

export const putSensorGroup = async (sensorGroupId, params) => {
  return $http.put(
    '/iot-sensor-cloud/1.0/sensor-group/' + sensorGroupId,
    params
  )
}

export const deleteSensorGroup = async (sensorGroupId) => {
  return $http.delete('/iot-sensor-cloud/1.0/sensor_groups/' + sensorGroupId)
}

export const fetchSensorTypes = async (store) => {
  return getSensorTypes().then((res) => {
    store.dispatch('data/saveSensorTypes', res.data.sensorType)
    return res.data
  })
}

export const fetchSensorDataAggregationTypes = async (store) => {
  return getSensorDataAggregationTypes().then((res) => {
    store.dispatch('data/saveSensorDataAggregationTypes', res.data.aggregationType)
    return res.data
  })
}

export const fetchAllSensors = async (store) => {
  return getAllSensors().then((res) => {
    store.dispatch('data/saveAllSensors', res.data.sensors)
    return res.data
  })
}

export const fetchAllSensorGroups = async (store) => {
  return getAllSensorGroups().then((res) => {
    store.dispatch('data/saveSensorGroups', res.data.sensorGroup)
    return res.data
  })
}

export const fetchAndNotifyAllSensorGroups = async (store) => {
  return fetchAllSensorGroups(store)
    .catch((e) => {
      notifyError('センサーグループのデータ取得に失敗しました。', e)
      return Promise.reject(e)
    })
}
