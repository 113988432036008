<template>
  <ModalDisplay>
    <v-card light class="pa-8">
      <v-checkbox v-model="mailFlag" class="ma-0" color="indigo" hide-details @change="onChangeSendMail">
        <template v-slot:label>
          <div> 製品に関するメール配信を希望する </div>
        </template>
      </v-checkbox>
      <v-col class="pa-0 mt-8 d-flex justify-end">
        <v-btn class="gradient white--text" :loading="loading" @click="close">閉じる</v-btn>
      </v-col>
    </v-card>
  </ModalDisplay>
</template>

<script>
import { fetchLoginUser } from '@/api/user'
import ModalDisplay from '@/components/ModalDisplay.vue'
import $http from '@/services/httpService'
import { getLocalStorage } from '@/store/localstorage'

export default {
  components: {
    ModalDisplay
  },
  data() {
    const loginUser = getLocalStorage('loginUser')
    return {
      loading: false,
      loginUser: loginUser,
      mailFlag: loginUser.mail_flag === 1,
    }
  },

  methods: {

    close() {
      this.$emit('switchSendMail', false)
    },

    onChangeSendMail() {
      this.loading = true
      $http.put('/auth/1.0/user/' + this.loginUser.app_user_id, {
        contractId: this.loginUser.contract_id,
        mail_flag: this.mailFlag? 1: 0
      })
      .then( async () => {
        await fetchLoginUser(this.$store)
      })
      .finally(()=> {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, #009ce6, #006ae7);
}
</style>
