import axios from 'axios'
import qs from 'qs';
import $auth from './authService'
import loading from './loadingService'
import { getLocalStorage } from '@/store/localstorage'
import logger from '@/lib/util/logger'
import { notifyError } from '@/lib/util/toast'

class HttpService {
  constructor() {
    this.$http = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
    })
    this.init()
  }

  init() {
    this.$http.interceptors.request.use(
      async (config) => {
        await $auth.checkAndRefresh()
        const data = getLocalStorage('data')
        const loginUser = getLocalStorage('loginUser')
        const IdToken = data.IdToken
        config.headers['Authorization'] = IdToken
        config.headers['LoginUser'] = encodeURIComponent(JSON.stringify(loginUser))
        return config
      },
      (error) => {
        logger.info(error)
      }
    )

    this.$http.interceptors.response.use(
      async (response) => {
        logger.info('レスポンスデータ: ', response)
        return response
      },
      async (error) => {
        logger.info('error: ', error)
        if (error.response.status === 401) {
          logger.info('401 ', error)
          $auth.checkAndRefresh()
        }

        if (error.response.status === 503 && error.response.data.message === 'maintenance now') {
          loading.close()
          location.href = '/maintenance'
        }

        if (error.response.status === 504 || error.code === 'ERR_NETWORK') {
          notifyError('タイムアウトあるいは通信に失敗しました。\n時間をおいて再度お試しください。', error)
          loading.close()
        }

        return Promise.reject(error)
      }
    )
  }

  async get(uri, params, options, writeAccessLog=false) {
    if (writeAccessLog) {
      this.accesslog(`GET ${uri}`, params)
    }
    const serializer = (params)  => {
      return qs.stringify(params, {arrayFormat: 'repeat'})
    }
    return this.$http.get(`${uri}`, { params: params, paramsSerializer: serializer}, options)
  }

  async post(uri, params, writeAccessLog=false) {
    if (writeAccessLog) {
      this.accesslog(`POST ${uri}`, params)
    }
    return this.$http.post(`${uri}`, params)
  }

  async put(uri, params, writeAccessLog=false) {
    if (writeAccessLog) {
      this.accesslog(`PUT ${uri}`, params)
    }
    return this.$http.put(`${uri}`, params)
  }

  async delete(uri, params, writeAccessLog=false) {
    if (writeAccessLog) {
      this.accesslog(`DELETE ${uri}`, params)
    }
    return this.$http.delete(`${uri}`, { params: params })
  }

  async accesslog(operation, params=undefined) {
    const user = getLocalStorage('loginUser')
    const contractId = user.contract_id
    const mail = user.mail_address
    const referrer = location.href
    this.$http.post('/logs/1.0/accesslogs', {operation, contractId, mail, referrer, params});
  }
}

export default new HttpService()
