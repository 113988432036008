import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import dataStore from './modules/data'
import userStore from './modules/user'
import viewStore from './modules/view'
import { clearLocalStorage } from '@/store/localstorage'

Vue.use(Vuex)

const initialState = () => {
  return {
    isLoading: false,
    unableTransitionFromSideBarMenu: false,
  }
}
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: initialState,
  getters: {
    unableTransitionFromSideBarMenu: (state) => state.unableTransitionFromSideBarMenu,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setUnableTransitionFromSideBarMenu(state, value) {
      state.unableTransitionFromSideBarMenu = value
    },
    setLoading(state, bool) {
      state.isLoading = bool
    },
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    switchUnableTransitionFromSideBarMenu({ commit }, value) {
      commit('setUnableTransitionFromSideBarMenu', value)
    },
    isLoading({ commit }, bool) {
      commit('setLoading', bool)
    },
    clearAll({ commit }){
      clearLocalStorage()
      commit("reset")
      commit("user/reset")
      commit("view/reset")
      commit("data/reset")
    },
  },
  modules: {
    user: userStore,
    view: viewStore,
    data: dataStore
  },
})
