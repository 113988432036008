import { clearLocalStorage, getLocalStorage } from '@/store/localstorage'
import logger from '@/lib/util/logger'

const authGuard = async (to, from, next) => {
  const storage = getLocalStorage('data')
  if (!storage || !from.path === '/login') {
    next('/login')
  } else {
    next()
  }
  next()
}

const authAdminGuard = async (to, from, next) => {
  const user = getLocalStorage('loginUser')
  if (!user.authority) {
    next('/')
  } else {
    authGuard(to, from, next)
  }
}

const rejectService = async (dialog) => {
  logger.debug(dialog)
  if (dialog) {
    clearLocalStorage()
    location.href = '/login'
  }
}

export { rejectService, authGuard, authAdminGuard }
