import axios from 'axios'
import dayjs from 'dayjs'
import { rejectService } from '@/router/authGuard'
import { getLocalStorage, setLocalStorage } from '@/store/localstorage'
import { CognitoJwtVerifier } from 'aws-jwt-verify'
import logger from '@/lib/util/logger'

class AuthService {
  constructor() {
    this.$auth = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
    })

    this.$idTokenVerifier = CognitoJwtVerifier.create({
      userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      tokenUse: 'id',
      clientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    })
  }

  async login(request) {
    const interceptor = this.interceptor()

    const version = 1.0
    return this.$auth.get(`auth/${version}/authenticate`, { headers: request, params: { mode: 'login' } }).finally(() => {
      this.$auth.interceptors.response.eject(interceptor)
    })
  }

  async verify(request) {
    const interceptor = this.interceptor()

    const version = 1.0
    return this.$auth.get(`auth/${version}/verify`, { headers: request }).finally(() => {
      this.$auth.interceptors.response.eject(interceptor)
    })
  }

  async conductChangePassword(request) {
    const interceptor = this.interceptor()

    const version = 1.0
    return this.$auth.get(`auth/${version}/authenticate`, { headers: request, params: { mode: 'changepassword'} }).finally(() => {
      this.$auth.interceptors.response.eject(interceptor)
    })
  }

  interceptor() {
    const interceptor = this.$auth.interceptors.response.use(
      async (response) => {
        if (response.data) {
          return this.success(response.data)
        }
      },
      (error) => {
        if (error.response.status === 503 && error.response.data.message === 'maintenance now') {
          location.href = '/maintenance'
        }
        return Promise.reject(error)
      }
    )
    return interceptor
  }

  async checkAndRefresh() {
    const idTokenExpire = getLocalStorage('idTokenTokenExpire')
    const now = dayjs().format('YYYY-MM-DD HH:mm')
    logger.debug('idToken期限切れ判定：期限切れ時間、現在時刻、判定値')
    logger.debug(idTokenExpire, now, now >= idTokenExpire)
    logger.debug('idToken期限切れ時間', idTokenExpire)
    if (now >= idTokenExpire) {
      try {
        const data = getLocalStorage('data')
        const user = getLocalStorage('loginUser')
        const authenticatedUserName = getLocalStorage('authenticatedUserName')
        const version = '1.0'
        const params = {
          refreshToken: data.RefreshToken,
          userName: authenticatedUserName,
          contractId: user.contract_id,
        }
        await this.$auth.get(`auth/${version}/refresh_token`, { headers: params }).then((res) => {
          this.resetToken(res.data)
        })
      } catch (e) {
        logger.warn('refresh error', e)
        // refreshでエラーだった場合は再ログインを促す。
        return rejectService(true)
      }
    }
  }

  async success(authData) {
    if (authData.IdToken) {
      setLocalStorage('data', authData)
      const tokenData = getLocalStorage('data')
      try {
        const payload = await this.$idTokenVerifier.verify(
          tokenData.IdToken // the JWT as string
        )
        setLocalStorage(
          'idTokenTokenExpire',
          dayjs.unix(payload.exp).format('YYYY-MM-DD HH:mm')
        )
      } catch {
        logger.info('IdToken Token not valid!')
      }
      return Promise.resolve({next: 'dashboard'})
    } else if (authData.Session) {
      setLocalStorage('session', authData.Session)
      logger.info('Mfa Start!')
      return Promise.resolve({next: 'mfa'})
    } else {
      return Promise.reject()
    }
  }

  async resetToken(newToken) {
    logger.info('resetToken')
    const tokenData = getLocalStorage('data')
    const resetTokenData = {
      IdToken: newToken.IdToken,
      AccessToken: newToken.AccessToken,
      RefreshToken: tokenData.RefreshToken,
    }
    setLocalStorage('data', resetTokenData)
    try {
      const payload = await this.$idTokenVerifier.verify(
        resetTokenData.IdToken // the JWT as string
      )
      setLocalStorage(
        'idTokenTokenExpire',
        dayjs.unix(payload.exp).format('YYYY-MM-DD HH:mm')
      )
    } catch {
      logger.info('IdToken not valid!')
    }
  }
}

export default new AuthService()
