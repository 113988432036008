import Vue from "vue";
import logger from '@/lib/util/logger'

export const notifySuccess = (message, options={}) => Vue.$toast.success(message, options)
export const notifyError = (message, error=undefined, options={}) => {
  if (error) {
    logger.error(error)
  }
  Vue.$toast.error(message, options)
}
