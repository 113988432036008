const initialState = () => {
  return {
    masterData: {
      sensorTypes: [],
      sensorDataAggregationTypes: []
    },
    gateways: [],
    contractInformation: {},
    sensorsByGWId: [],
    allSensors: [],
    sensorGroups: [],
    securityPolicy: {},
  }
}
const dataStore = {
  namespaced: true,
  state: initialState,
  getters: {
    masterData: (state) => state.masterData,
    gateways: (state) => state.gateways,
    contractInformation: (state) => state.contractInformation,
    sensorsByGWId: (state) => state.sensorsByGWId,
    allSensors: (state) => state.allSensors,
    sensorGroups: (state) => state.sensorGroups,
    securityPolicy: (state) => state.securityPolicy,
  },
  mutations: {
    setSensorTypes(state, values) {
      state.masterData.sensorTypes = values
    },
    setSensorDataAggregationTypes(state, values) {
      state.masterData.sensorDataAggregationTypes = values
    },
    setGateways(state, values) {
      state.gateways = values
    },
    setContractInformation(state, value) {
      state.contractInformation = value
    },
    setSensorsByGWId(state, values) {
      state.sensorsByGWId = values
    },
    setAllSensors(state, values) {
      state.allSensors = values
    },
    setSensorGroups(state, values) {
      state.sensorGroups = values
    },
    setSecurityPolicy(state, value) {
      state.securityPolicy = value
    },
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    saveSensorTypes({ commit }, values) {
      commit('setSensorTypes', values)
    },
    saveSensorDataAggregationTypes({ commit }, values) {
      commit('setSensorDataAggregationTypes', values)
    },
    saveGateways({ commit }, values) {
      commit('setGateways', values)
    },
    saveContractInformation({ commit }, value) {
      commit('setContractInformation', value)
    },
    saveSensorsByGWId({ commit }, values) {
      commit('setSensorsByGWId', values)
    },
    saveAllSensors({ commit }, values) {
      commit('setAllSensors', values)
    },
    saveSensorGroups({ commit }, values) {
      commit('setSensorGroups', values)
    },
    saveSecurityPolicy({ commit }, value) {
      commit('setSecurityPolicy', value)
    },
  }
}

export default dataStore
