export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const clearLocalStorage = () => {
  // FIXME: move store
  localStorage.removeItem('data')
  localStorage.removeItem('session')
  localStorage.removeItem('authenticatedUserName')
  localStorage.removeItem('loginUser')
  localStorage.removeItem('idTokenTokenExpire')
  localStorage.removeItem('viewSettings')
}
